import config from '../utils/env';

interface UrlConstants {
  mynib: string;
  careers: string;
  newsAndMedia: string;
  getQuickQuote: (promoCode?: string) => string;
  getQuoteWidgetUrl: string;
  cnJoin: string;
  symptomChecker: string;
  contactUs: string;
  appleAppStore: string;
  googlePlayStore: string;
  nwoPortal: string;
  findAProvider: string;
  employerInsurance: string;
  employerInsuranceCallbackSection: string;
  travelInsurance: string;
  compareLifeAndLivingInsurance: string;
  lifeAndLivingInsurance: string;
  nonPharmac: string;
  bluesShareADay: string;
  bluesRugby: string;
  foundationContactForm: string;
  foundation: string;
  foundationProjectClearhead: string;
  foundationUniversityOfOtago: string;
  foundationPreventionPartnership: string;
  ngatiWhatuaOrakei: string;
}

export const urlConstants: UrlConstants = {
  mynib: `${config.portalUrl}`,
  careers: 'https://careers.nib.co.nz',
  newsAndMedia: '/news-and-media/news-details/free-health-and-dental-check-ups',
  getQuickQuote: (promoCode) =>
    promoCode ? `${config.dtcJoinUrl}?promoCode=${promoCode}` : `${config.dtcJoinUrl}`,
  getQuoteWidgetUrl: `${config.dtcJoinUrl}`,
  cnJoin: `${config.dtcJoinUrl}?locale=zh`,
  symptomChecker: 'https://health.nib.co.nz/mynib',
  contactUs: 'https://health.nib.co.nz/contact-us',
  appleAppStore: 'https://apps.apple.com/us/app/my-nib-nz/id968982318',
  googlePlayStore: 'https://play.google.com/store/apps/details?id=nz.co.nib.mynib&hl=en',
  nwoPortal: 'https://www.health-iq.com.au/Portal/Member/login/nwo',
  findAProvider: '/find-a-provider',
  employerInsurance: 'https://health.nib.co.nz/business',
  employerInsuranceCallbackSection: 'https://health.nib.co.nz/business#callback_section_panel',
  travelInsurance: 'https://www.nibtravel.co.nz/',
  compareLifeAndLivingInsurance: '/life-cover-options',
  lifeAndLivingInsurance: 'https://health.nib.co.nz/life-and-living-insurance',
  nonPharmac: '/non-pharmac',
  bluesShareADay:
    '/news-and-media/news-details/nib-blues-share-a-day-in-the-life-as-a-professional-womens-rugby-player/',
  bluesRugby: 'https://www.blues.rugby/',
  foundationContactForm: 'https://www.nib.com.au/foundation/contact-us',
  foundation: 'https://www.nib.com.au/foundation/',
  foundationProjectClearhead: 'https://www.nib.com.au/foundation/project/clearhead-nz',
  foundationUniversityOfOtago: 'https://www.nib.com.au/foundation/project/university-of-otago-nz',
  foundationPreventionPartnership:
    'https://www.nib.com.au/foundation/prevention-partnerships-program',
  ngatiWhatuaOrakei: '/ngatiwhatuaorakei',
};

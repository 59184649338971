import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link as GatsbyLink } from 'gatsby';

import Heading from '@nib-components/heading';
import { Stack, Box, Section } from '@nib/layout';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { pb, pt, px } from 'styled-components-spacing';
import Copy from '@nib-components/copy';
import Link from '@nib-components/link';

import Layout from '../../components/Layout';
import metrics from '../../metrics';
import PiggybackSmall from '../../img/nib-in-the-community/small.jpg';
import PiggybackLarge from '../../img/nib-in-the-community/large.jpg';
import AucklandBlues from '../../img/nib-in-the-community/auckland_blues.jpg';
import { urlConstants } from '../../constructs/constants';
import { ContentfulDocument } from '../../constructs/models';

export const NibInTheCommunityQuery = graphql`
  query NibInTheCommunityQuery {
    allContentfulDocument(filter: { name: { eq: "Sponsorship Request From" } }, limit: 1) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'gridItem1'
    'gridItem3'
    'gridItem2';
  ${breakpoint('lg')`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'gridItem1 gridItem3'
      'gridItem2 gridItem3'
  `};
`;

const GridItem1 = styled.div`
  grid-area: gridItem1;
  ${breakpoint('lg')`
    ${px(4)};
    ${pb(0)};
  `};
`;

const GridItem3 = styled.div`
  grid-area: gridItem3;
  display: grid;
  ${breakpoint('lg')`
    min-height: 475px;
  `};
`;

const ValueImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  text-align: center;

  ${breakpoint('lg')`
    height: 100%;
    width: auto;
    text-align: left;
  `};
`;

const ValueImage = styled.img`
  height: auto;
  width: 100%;
  ${pt(4)};

  ${breakpoint('lg')`
    position: absolute;
    left: -45%;
    ${pt(0)};
    ${(props) =>
      !props.thin
        ? css`
            height: 100%;
            width: auto;
          `
        : css`
            width: 100%;
          `}
  `};

  ${breakpoint('xl')`
  position: absolute;
  left: -20%;
  ${pt(0)};
  ${(props) =>
    !props.thin
      ? css`
          height: 100%;
          width: auto;
        `
      : css`
          width: 100%;
        `}
`};

  ${breakpoint('xxl')`
    position: absolute;
    left: 0%;
    ${pt(0)};
    ${(props) =>
      !props.thin
        ? css`
            height: 100%;
            width: auto;
          `
        : css`
            width: 100%;
          `}
  `};
`;

const HeroSection = (): JSX.Element => (
  <Section role="section" background="warmWhite60">
    <Grid>
      <GridItem1>
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
            What we do in the community
          </Heading>
          <Copy measure={false}>
            At nib, our purpose is your better health and wellbeing. We exist to help Kiwis and
            their families to live healthier, happier lives and support a range of events,
            organisations, initiatives, and health programmes in the community.
          </Copy>
          <Copy measure={false}>
            We believe a strong sense of belonging, social justice, practicing tolerance and respect
            of differences is essential to fostering resilience in healthy communities. Our
            partnerships with the community help to reduce inequalities, with a special focus on
            preventative health, mental wellbeing and Māori health.
          </Copy>
        </Stack>
      </GridItem1>
      <GridItem3>
        <ValueImageWrapper>
          <picture>
            <source media="(max-width:640px)" srcSet={`${PiggybackSmall}`} />
            <ValueImage src={PiggybackLarge}></ValueImage>
          </picture>
        </ValueImageWrapper>
      </GridItem3>
    </Grid>
  </Section>
);

interface PartnerSectionProps {
  sponsorshipRequestForm: ContentfulDocument;
}
const PartnersSection = ({ sponsorshipRequestForm }: PartnerSectionProps): JSX.Element => (
  <>
    <Section role="section">
      <Box marginBottom="8">
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
            The Blues
          </Heading>
          <Copy measure={false}>
            We've been proud sponsors of <Link href={urlConstants.bluesRugby}>the Blues</Link> for
            more than a decade, becoming Principal Partners in 2015. In 2022, we became the naming
            rights partner of the nib Blues Women's team for their inaugural Super Rugby Aupiki
            competition, helping pave the way for greater representation of women and diversity in
            sport. As part of our sponsorship, we also dedicated funding towards the development and
            wellbeing of the team, so, they can focus on playing at their best and alleviate some of
            those external pressures as they juggle work and family life.
          </Copy>
          <picture>
            <img src={AucklandBlues} width="250"></img>
          </picture>
        </Stack>
      </Box>
      <Box marginBottom="8">
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
            nib foundation
          </Heading>
          <Copy measure={false}>
            In 2021, nib’s charitable foundation,{' '}
            <Link href={urlConstants.foundation}>nib foundation</Link>, expanded their Prevention
            Partnerships funding program to New Zealand to support community initiatives that focus
            on health promotion and prevention. Our partners include{' '}
            <Link href={urlConstants.foundationProjectClearhead}>Clearhead</Link> and the{' '}
            <Link href={urlConstants.foundationUniversityOfOtago}>University of Otago</Link>.
          </Copy>
          <Copy measure={false}>
            Through our funding support, Clearhead is growing their online platform to include an
            interactive digital wellbeing tool to help young adults find their purpose in life.
          </Copy>
          <Copy measure={false}>
            The University of Otago will analyse data on youth drinking and experience of alcohol
            harm, to produce a series of resources addressing harmful drinking in young people and
            reducing disparities in alcohol harm.
          </Copy>
          <Copy measure={false}>
            Learn more about nib foundation’s{' '}
            <Link href={urlConstants.foundationPreventionPartnership}>Prevention funding</Link>.
          </Copy>
        </Stack>
      </Box>
      <Box marginBottom="8">
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
            Employee Volunteering
          </Heading>
          <Copy measure={false}>
            We encourage our employees to give back to the communities where they live and work
            through our nib give corporate volunteering program and employee-led fundraising events.
            We partner with local charities to provide opportunities for our employees to come
            together and make a difference in their community.
          </Copy>
        </Stack>
      </Box>
      <Box marginBottom="8">
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
            Toi Ora programme
          </Heading>
          <Copy measure={false}>
            In 2018, we launched a first of its kind programme with Auckland ropū,{' '}
            <Link href={urlConstants.ngatiWhatuaOrakei}>Ngāti Whātua Ōrākei</Link>, to provide
            universal private health insurance for all members of the hapū to help improve health
            and wellbeing outcomes. In 2021, we launched health insurance for employees of Te
            Runanganui o Ngati Porou to do the same.
          </Copy>
          <Copy measure={false}>
            Since then, we’ve been working together to develop new health management programmes and
            additional benefits for traditional Māori treatment, such as Rongoā Māori, to support
            better health outcomes for their whanau (family).
          </Copy>
          <Copy measure={false}>
            These include initiatives like our Molemap program, providing free 15-minute head-to-toe
            skin checks as well as skin cancer education, with the aim to improve access to skin
            health services and encourage early diagnosis through higher screening rates.
          </Copy>
          <Copy measure={false}>
            We’ve also partnered with HealthScreening NZ to provide non-invasive screening for
            cardiovascular disease. Designed to identify early signs of heart disease in iwi
            members, the screening tool links to the Cardiaction app, offering actionable advice
            that reduces the risk of disease progression.
          </Copy>
        </Stack>
      </Box>
      <Box>
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
            Ambassadors
          </Heading>
          <Copy measure={false}>
            Our community ambassadors provide expert advice on Kiwis’ better health.
          </Copy>
          <Copy measure={false}>
            In 2018, we partnered with Nathan Wallis as our nib parenting expert. Nathan is a
            Neuroscience Educator and hosts sold-out learning events for parents, discussing
            different stages of child development.
          </Copy>
          <Copy measure={false}>
            In 2022, we welcomed mental health advocate Jimi Hunt as our first Mental Fitness
            Champion. Jimi hosts important conversations about mental health and creates accessible
            resources to help Kiwis be more proactive about their mental health and wellbeing in the
            workplace and at home.
          </Copy>
        </Stack>
      </Box>
    </Section>
    <Section role="section" background="sageGreen60">
      <Box marginBottom="8">
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
            Apply to be a partner
          </Heading>
          <Copy measure={false}>
            If you think you or your organisation or community group would align well with our
            sponsorships and mission of your better health and wellbeing you can send an expression
            of interest through the channels below.
          </Copy>
          <Copy measure={false}>
            For sponsorship enquiries, please complete this{' '}
            <Link href={sponsorshipRequestForm?.file?.url}>form</Link> and email it to{' '}
            <Link href="mailto:sponsorship@nib.co.nz?subject=Request for sponsorship">
              sponsorship@nib.co.nz
            </Link>
            .
          </Copy>
          <Copy measure={false}>
            For volunteering or community partnerships enquiries, contact nib foundation via their{' '}
            <Link href={urlConstants.foundationContactForm}>online enquiry form</Link>.
          </Copy>
        </Stack>
      </Box>
    </Section>
  </>
);

const title = 'Nib In The Community';
const metaDescription =
  'Clearhead, a Kiwi digital mental health company has launched its te reo Māori website ' +
  'and chatbot offering in partnership with us and nib foundation.';

interface GraphQLNibInTheCommunityDocumentNode {
  edges: ContentfulInTheCommunityDocumentEdgeShape[];
}

interface ContentfulInTheCommunityDocumentEdgeShape {
  node: {
    document: ContentfulDocument;
  };
}

interface NibInTheCommunityDocumentProps {
  data: {
    allContentfulDocument: GraphQLNibInTheCommunityDocumentNode;
  };
}

const NibInTheCommunity = (props: NibInTheCommunityDocumentProps): JSX.Element => {
  const sponsorshipRequestForm = props?.data?.allContentfulDocument?.edges?.[0]?.node?.document;
  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Section role="section">
        <Stack space={3}>
          <Link id="ga-inlink-static-site-page-9" component={GatsbyLink} to="/about-nib">
            &lt; Back
          </Link>
          <Heading
            align="center"
            color="trueGreen"
            size={{ xs: 2, sm: 1 }}
            fontType="serif"
            component="h1"
          >
            {title}
          </Heading>
        </Stack>
      </Section>
      <HeroSection />
      <PartnersSection sponsorshipRequestForm={sponsorshipRequestForm} />
    </Layout>
  );
};

export default metrics({ pageName: 'nib-in-the-community' })(NibInTheCommunity);
